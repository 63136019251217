import React, { useState, useRef } from 'react';

// Context
import FileContext from './FileContext';

interface IFileStateProps {
  children: React.ReactNode;
}

interface IHandleSlider {
  transaction: any;
  fanData: any;
}

interface ISavingPreviousData {
  transactionId: string;
  file: {
    preview: string;
    raw: any;
  };
  rejectMessage: string;
}

const fileInitialState = {
  preview: '/transparent.png',
  raw: null
};

const DashboardV2State = ({ children }: IFileStateProps) => {
  const savingPreviousData = useRef<ISavingPreviousData[]>([]);

  const [showInputReject, setShowInputReject] = useState<boolean>(false);
  const [file, setFile] = useState(fileInitialState);

  const updatePreviousData = (transactionId: string, file: any) => {
    const findIndex = savingPreviousData.current.findIndex(
      (user) => user.transactionId === transactionId
    );

    if (findIndex === -1) return;

    savingPreviousData.current[findIndex].file = file;
    // console.log(savingPreviousData.current);
  };

  return (
    <FileContext.Provider
      value={{
        showInputReject,
        setShowInputReject,
        file,
        setFile,
        updatePreviousData
      }}
    >
      {children}
    </FileContext.Provider>
  );
};

export default DashboardV2State;
