import { userTypes } from '../types';
import { IUser, IUserEdit, IUserEditSettings } from 'types/User';
import { AxiosApi } from 'requests/AxiosBase';

// Helpers
import { getCookie, delCookie } from 'helpers/handleCookies';

export const isUserAuthenticated = async () => {
  const userToken = getCookie('token');

  if (!userToken) {
    return {
      type: userTypes.no_authenticated
    };
  }

  try {
    const { data } = await AxiosApi.get('/auth', {
      headers: {
        Authorization: `Bearer ${userToken}`
      }
    });

    if (data.message !== 'OK') throw 'No estas logeado';

    return {
      type: userTypes.authenticated,
      payload: data.payload
    };
  } catch (e) {
    console.log(e);
    console.log('isUserAuthenticated() Error');
    return {
      type: userTypes.no_authenticated
    };
  }
};

export const userAuthenticating = (payload: IUser) => {
  return {
    type: userTypes.authenticating,
    payload
  };
};

export const editUser = (payload: IUserEdit) => {
  return {
    type: userTypes.edit_user,
    payload
  };
};

export const userLogout = () => {
  delCookie('token');

  return {
    type: userTypes.logout
  };
};

// User edit functions

export const userEditFields = (payload: IUserEditSettings) => {
  return {
    type: userTypes.edit_user_fields,
    payload
  };
};
