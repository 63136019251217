import { createContext } from "react";

export interface ILangContext {
    provider_authentication: {
        google: string;
        twitter: string;
    }

    sign_in: {
        title: string;
        input_email: string;
        input_password: string;
        forgot_password: string;
        button: string;
        not_account_0: string;
        not_account_1: string;
    }
}

export const initialState: ILangContext = {
    provider_authentication: {
        google: "",
        twitter: ""
    },

    sign_in: {
        title: "",
        input_email: "",
        input_password: "",
        forgot_password: "",
        button: "",
        not_account_0: "",
        not_account_1: ""
    }
}

export const LangContext = createContext<ILangContext>(initialState);