import { AppRedux } from 'types/App';
import { AppActions, AppTypes } from './appTypes';

const initialState: AppRedux = {
  blockScreen: {
    stripeConnect: {
      isConnectEnabled: false,
      isLoading: true
    }
  },
  audienceScreen: {
    isSubscriptionsLoading: true,
    subscriptions: [],
    wallets: [],
    isWalletsLoading: true
  },
  subscriptionScreen: {
    isLoading: true,
    subscriptions: []
  },
  analyticsScreen: {
    isLoading: true,
    data: {
      optionSelected: {
        textContent: 'Hoy',
        value: 'today',
        defaultValue: true
      },
      clicks: [],
      donations: 0,
      greetings: 0,
      questions: 0,
      audios: 0,
      digital_products: 0,
      subscriptions: { balance: 0, quantity: 0, growth: 0 },
      visits: 0
    }
  },
  productsScreen: {
    isLoading: true,
    digitalProducts: []
  },
  purchaseScreen: {
    option: { textContent: 'Esta semana', value: 'weekly' }
  },
  isProfileViewerOpen: false
};

const appReducer = (state = initialState, action: AppActions) => {
  const { type, payload } = action;

  switch (type) {
    case AppTypes.edit_block_screen:
      return {
        ...state,
        blockScreen: {
          ...state.blockScreen,
          ...payload
        }
      };
    case AppTypes.edit_audience_screen:
      return {
        ...state,
        audienceScreen: {
          ...state.audienceScreen,
          ...payload
        }
      };
    case AppTypes.edit_subscriptions_screen:
      return {
        ...state,
        subscriptionScreen: {
          ...state.subscriptionScreen,
          ...payload
        }
      };
    case AppTypes.edit_analytics_screen:
      return {
        ...state,
        analyticsScreen: {
          ...state.analyticsScreen,
          ...payload
        }
      };
    case AppTypes.edit_products_screen:
      return {
        ...state,
        productsScreen: {
          ...state.productsScreen,
          ...payload
        }
      };
    case AppTypes.edit_purchase_screen:
      return {
        ...state,
        purchaseScreen: {
          ...state.purchaseScreen,
          ...payload
        }
      };
    case AppTypes.edit_profile_viewer:
      return {
        ...state,
        isProfileViewerOpen: payload
      };
    case AppTypes.user_logout:
      return initialState;
    default:
      return state;
  }
};

export default appReducer;
