export type TPalette =
  | 'red'
  | 'pink'
  | 'yellow'
  | 'green'
  | 'cyan'
  | 'blue'
  | 'purple'
  | 'fuchsia';

const ColorsDeclaration: React.FC = () => {
  return (
    <div>
      <div className="hidden bg-red-500 fill-red-500 text-red-500  hover:bg-red-800 disabled:hover:bg-red-500" />
      <div className="hidden bg-red-100 hover:bg-red-400 hover:text-red-200 disabled:hover:bg-red-100 text-red-800" />
      <div className="hidden bg-red-200 text-red-600" />
      <div className="hidden bg-red-600" />

      <div className="hidden bg-pink-500 fill-pink-500  text-pink-500 hover:bg-pink-800" />
      <div className="hidden bg-pink-100 hover:bg-pink-400 text-pink-800 hover:text-pink-200 disabled:hover:bg-pink-100" />
      <div className="hidden bg-pink-200 text-pink-600" />
      <div className="hidden bg-pink-600" />

      <div className="hidden bg-amber-500 fill-amber-500  text-amber-500 hover:bg-amber-800" />
      <div className="hidden bg-amber-100 hover:bg-amber-400 hover:text-amber-200 disabled:hover:bg-amber-100 text-amber-800" />
      <div className="hidden bg-amber-200 text-amber-600" />
      <div className="hidden bg-amber-600" />

      <div className="hidden bg-yellow-500 fill-yellow-500  text-yellow-500 hover:bg-yellow-800" />
      <div className="hidden bg-yellow-100 hover:bg-yellow-400 hover:text-yellow-200 disabled:hover:bg-yellow-100 text-yellow-800" />
      <div className="hidden bg-yellow-200 text-yellow-600" />
      <div className="hidden bg-yellow-600" />

      <div className="hidden bg-green-500 fill-green-500   text-green-500 hover:bg-green-800" />
      <div className="hidden bg-green-100 hover:bg-green-400 hover:text-green-200 disabled:hover:bg-green-100 text-green-800" />
      <div className="hidden bg-green-200 text-green-600" />
      <div className="hidden bg-green-600" />

      <div className="hidden bg-cyan-500 fill-cyan-500  text-cyan-500 hover:bg-cyan-800" />
      <div className="hidden bg-cyan-100 hover:bg-cyan-400 hover:text-cyan-200 disabled:hover:bg-cyan-100 text-cyan-800" />
      <div className="hidden bg-cyan-200 text-cyan-600" />
      <div className="hidden bg-cyan-600" />

      <div className="hidden bg-blue-500 fill-blue-500   text-blue-500 hover:bg-blue-800 disabled:hover:bg-blue-500" />
      <div className="hidden bg-blue-100 hover:bg-blue-400 hover:text-blue-200 disabled:hover:bg-blue-100 text-blue-800" />
      <div className="hidden bg-blue-200 text-blue-600" />
      <div className="hidden bg-blue-600" />

      <div className="hidden bg-purple-500 fill-purple-500  text-purple-500 hover:bg-purple-800" />
      <div className="hidden bg-purple-100 hover:bg-purple-400 hover:text-purple-200 disabled:hover:bg-purple-100 text-purple-800" />
      <div className="hidden bg-purple-200 text-purple-600" />
      <div className="hidden bg-purple-600" />

      <div className="hidden bg-fuchsia-500 fill-fuchsia-500   text-fuchsia-500 hover:bg-fuchsia-800" />
      <div className="hidden bg-fuchsia-100 hover:bg-fuchsia-400 hover:text-fuchsia-200 disabled:hover:bg-fuchsia-100 text-fuchsia-800" />
      <div className="hidden bg-fuchsia-200 text-fuchsia-600" />
      <div className="hidden bg-fuchsia-600" />

      <div className="hidden bg-jacarta-600 hover:bg-jacarta-700 active:bg-jacarta-800 text-jacarta-700"/>
      <div className="hidden bg-jacarta-700 hover:bg-jacarta-800 active:bg-jacarta-900 text-jacarta-800"/>
      <div className="hidden hover:bg-jacarta-200"/>

      <div className="hidden bg-ultramarine-600 hover:bg-ultramarine-700 active:bg-ultramarine-800 text-ultramarine-700"/>
      <div className="hidden bg-ultramarine-700 hover:bg-ultramarine-800 active:bg-ultramarine-900 text-ultramarine-800"/>
      <div className="hidden hover:bg-ultramarine-200"/>

      <div className="hidden bg-darktan-600 hover:bg-darktan-700 active:bg-darktan-800 text-darktan-700"/>
      <div className="hidden bg-darktan-700 hover:bg-darktan-800 active:bg-darktan-900 text-darktan-800"/>
      <div className="hidden hover:bg-darktan-200"/>

      <div className="hidden bg-mallard-600 hover:bg-mallard-700 active:bg-mallard-800 text-mallard-700"/>
      <div className="hidden bg-mallard-700 hover:bg-mallard-800 active:bg-mallard-900 text-mallard-800"/>
      <div className="hidden hover:bg-mallard-200"/>

      <div className="hidden bg-ebonyclay-600 hover:bg-ebonyclay-700 active:bg-ebonyclay-800 text-ebonyclay-700"/>
      <div className="hidden bg-ebonyclay-700 hover:bg-ebonyclay-800 active:bg-ebonyclay-900 text-ebonyclay-800"/>
      <div className="hidden hover:bg-ebonyclay-200"/>

      <div className="hidden bg-eagle-600 hover:bg-eagle-700 active:bg-eagle-800 text-eagle-700"/>
      <div className="hidden bg-eagle-700 hover:bg-eagle-800 active:bg-eagle-900 text-eagle-800"/>
      
      {/* colores anteriores */}
      <div className="hidden bg-red-600 hover:bg-red-700 active:bg-red-800 text-red-700"/>
      <div className="hidden bg-red-700 hover:bg-red-800 active:bg-red-900"/>
      <div className="hidden hover:bg-red-200"/>

      <div className="hidden bg-pink-600 hover:bg-pink-700 active:bg-pink-800 text-pink-700"/>
      <div className="hidden bg-pink-700 hover:bg-pink-800 active:bg-pink-900"/>
      <div className="hidden hover:bg-pink-200"/>

      <div className="hidden bg-amber-600 hover:bg-amber-700 active:bg-amber-800 text-amber-700"/>
      <div className="hidden bg-amber-700 hover:bg-amber-800 active:bg-amber-900"/>
      <div className="hidden hover:bg-amber-200"/>

      <div className="hidden bg-yellow-600 hover:bg-yellow-700 active:bg-yellow-800 text-yellow-700"/>
      <div className="hidden bg-yellow-700 hover:bg-yellow-800 active:bg-yellow-900"/>
      <div className="hidden hover:bg-yellow-200"/>

      <div className="hidden bg-green-600 hover:bg-green-700 active:bg-green-800 text-green-700"/>
      <div className="hidden bg-green-700 hover:bg-green-800 active:bg-green-900"/>
      <div className="hidden hover:bg-green-200"/>

      <div className="hidden bg-cyan-600 hover:bg-cyan-700 active:bg-cyan-800 text-cyan-700"/>
      <div className="hidden bg-cyan-700 hover:bg-cyan-800 active:bg-cyan-900"/>
      <div className="hidden hover:bg-cyan-200"/>

      <div className="hidden bg-blue-600 hover:bg-blue-700 active:bg-blue-800 text-blue-700"/>
      <div className="hidden bg-blue-700 hover:bg-blue-800 active:bg-blue-900"/>
      <div className="hidden hover:bg-blue-200"/>

      <div className="hidden bg-purple-600 hover:bg-purple-700 active:bg-purple-800 text-purple-700"/>
      <div className="hidden bg-purple-700 hover:bg-purple-800 active:bg-purple-900"/>
      <div className="hidden hover:bg-purple-200"/>

      <div className="hidden bg-fuchsia-600 hover:bg-fuchsia-700 active:bg-fuchsia-800 text-fuchsia-700"/>
      <div className="hidden bg-fuchsia-700 hover:bg-fuchsia-800 active:bg-fuchsia-900"/>
      <div className="hidden hover:bg-fuchsia-200"/>

      <div className="hidden bg-ebonyclay-100 hover:bg-ebonyclay-200"/>
      <div className="hidden bg-eagle-100 hover:bg-eagle-200"/>

      <div className="hidden bg-red-200 text-red-700"/>
      <div className="hidden bg-pink-200 text-pink-700"/>
      <div className="hidden bg-amber-200 text-amber-700"/>
      <div className="hidden bg-yellow-200 text-yellow-700"/>
      <div className="hidden bg-green-200 text-green-700"/>
      <div className="hidden bg-cyan-200 text-cyan-700"/>
      <div className="hidden bg-blue-200 text-blue-700"/>
      <div className="hidden bg-purple-200 text-purple-700"/>
      <div className="hidden bg-fuchsia-200 text-fuchsia-700"/>
      <div className="hidden bg-jacarta-200 text-jacarta-700"/>
      <div className="hidden bg-ultramarine-200 text-ultramarine-700"/>
      <div className="hidden bg-darktan-200 text-darktan-700"/>
      <div className="hidden bg-mallard-200 text-mallard-700"/>
      <div className="hidden bg-ebonyclay-200 text-ebonyclay-700"/>
      <div className="hidden bg-eagle-200 text-eagle-700"/>

      <div className="hidden bg-red-200 hover:bg-red-400 text-red-800"/>
      <div className="hidden bg-pink-200 hover:bg-pink-400 text-pink-800"/>
      <div className="hidden bg-amber-200 hover:bg-amber-400 text-amber-800"/>
      <div className="hidden bg-yellow-200 hover:bg-yellow-400 text-yellow-800"/>
      <div className="hidden bg-green-200 hover:bg-green-400 text-green-800"/>
      <div className="hidden bg-cyan-200 hover:bg-cyan-400 text-cyan-800"/>
      <div className="hidden bg-blue-200 hover:bg-blue-400 text-blue-800"/>
      <div className="hidden bg-purple-200 hover:bg-purple-400 text-purple-800"/>
      <div className="hidden bg-fuchsia-200 hover:bg-fuchsia-400 text-fuchsia-800"/>
      <div className="hidden bg-jacarta-200 hover:bg-jacarta-400 text-jacarta-800"/>
      <div className="hidden bg-ultramarine-200 hover:bg-ultramarine-400 text-ultramarine-800"/>
      <div className="hidden bg-darktan-200 hover:bg-darktan-400 text-darktan-800"/>
      <div className="hidden bg-mallard-200 hover:bg-mallard-400 text-mallard-800"/>
      <div className="hidden bg-ebonyclay-200 hover:bg-ebonyclay-400 text-ebonyclay-800"/>
      <div className="hidden bg-eagle-200 hover:bg-eagle-400 text-eagle-800"/>

    </div>
  );
};

export default ColorsDeclaration;
