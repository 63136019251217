import 'assets/chrome-bug.scss';
import 'assets/main.scss';
import 'assets/skeleton.css';
import 'assets/tailwind.css';
import 'assets/unreset.scss';
import {
  CategoryScale,
  Chart,
  Filler,
  LinearScale,
  LineElement,
  PointElement,
  Tooltip
} from 'chart.js';
import MainLayout from 'components/MainLayout';
import FileState from 'context/file/FileState';
import LangState from 'context/lang/LangState';
import { useRouter } from 'next/router';
import nprogress from 'nprogress';
import { useEffect } from 'react';
import { Toaster } from 'react-hot-toast';
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css';
import { Provider } from 'react-redux';
import store from 'reduxState/store';
import 'assets/hamburguesa.css';
import 'react-alice-carousel/lib/alice-carousel.css';
import ErrorPage from 'screens/errors/ErrorPage';

Array.prototype.findIndexLast = function (cb: (value: any) => boolean) {
  let data: number = -1;

  for (let i = this.length - 1; i >= 0; i--) {
    const isValid = cb(this[i]);

    if (isValid) {
      data = i;
      break;
    }
  }

  return data;
};

Chart.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Filler
);

export default function MyApp({ Component, pageProps }) {
  useEffect(() => {
    document.body.classList?.remove('loading');
  }, []);

  const router = useRouter();

  useEffect(() => {
    const routeChangeStart = () => nprogress.start();

    const routeChangeComplete = () => nprogress.done();

    router.events.on('routeChangeStart', routeChangeStart);
    router.events.on('routeChangeComplete', routeChangeComplete);
    router.events.on('routeChangeError', routeChangeComplete);

    return () => {
      router.events.off('routeChangeStart', routeChangeStart);
      router.events.off('routeChangeComplete', routeChangeComplete);
      router.events.off('routeChangeError', routeChangeComplete);
    };
  }, [router]);

  return (
    <>
      <Toaster position="bottom-center" reverseOrder={false} />
      <Provider store={store}>
        <FileState>
          <LangState>
            <MainLayout>
              <Component {...pageProps} />
            </MainLayout>
          </LangState>
        </FileState>
      </Provider>
    </>
  );
}
