import React, { useState, useEffect } from 'react';

// Components
import LottieLoader from './LottieLoader';
import RoundFilter from 'components/tailwind/shapes/RoundFilter';
import ColorsDeclaration from 'components/tailwind/pallete/ColorsDeclaration';

// Hooks
import { useUser } from 'hooks/useUser';

// Redux
import { isUserAuthenticated } from 'reduxState/user/userState';
import { useDispatch } from 'react-redux';

interface IMainLayoutProps {
  children: React.ReactNode;
}

const MainLayout = ({ children }: IMainLayoutProps) => {
  const { status, user } = useUser();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const effectFunc = async () => {
      const userAuthenticated = await isUserAuthenticated();

      dispatch(userAuthenticated);

      setLoading(false);
    };

    effectFunc();
  }, []);

  useEffect(() => {
    if (status === 0) return;

    const $js_intercom = document.getElementById('js_intercom');

    if (status === 2) {
      if ($js_intercom) {
        const $intercomLightWeightApp = document.querySelector(
          '.intercom-lightweight-app'
        );
        const $intercomIframe = document.getElementById('intercom-frame');

        if ($intercomLightWeightApp) {
          document.body.removeChild($intercomLightWeightApp);
        }

        if ($intercomIframe) {
          document.body.removeChild($intercomIframe);
        }

        document.body.removeChild($js_intercom);
      }

      return;
    } else if ($js_intercom) return;

    const $script = document.createElement('script');
    $script.id = 'js_intercom';
    $script.src = '/intercom.js';

    $script.dataset.user_id = user.id;
    $script.dataset.username = user.username;
    $script.dataset.email = user.email || '';
    $script.dataset.created_at = new Date(user.createdAt).getTime().toString();
    $script.dataset.profile_picture = user.profilePicture;

    document.body.appendChild($script);
  }, [status]);

  return (
    <>
      {loading && <LottieLoader />}
      {children}
      <RoundFilter />
      <ColorsDeclaration />
    </>
  );
};

export default MainLayout;
