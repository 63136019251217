import React, { useReducer, useEffect } from "react";
import { LangContext, initialState } from "./LangContext";
import langReducer from "./langReducer";

// Langs
import spanish_es from "lang/spanish_es.json";
import english_us from "lang/english_us.json";

interface ILangStateProps {
    children: React.ReactNode;
}

const LangState = ({ children }: ILangStateProps) => {
    const [state, dispatch] = useReducer(langReducer, initialState);

    useEffect(() => {
        const getLang = localStorage.getItem("mf_lang");

        if (getLang === "english_us") {
            dispatch({
                type: "english_us",
                payload: english_us
            });
            return
        }

        dispatch({
            type: "spanish_es",
            payload: spanish_es
        })
    }, [])

    return (
        <LangContext.Provider value={state}>
            {children}
        </LangContext.Provider>
    )
}

export default LangState;