import React, { useEffect, useRef } from 'react';
import lottie from 'lottie-web';

const LottieLoader = () => {
  const divRef = useRef<HTMLDivElement>();

  useEffect(() => {
    lottie.loadAnimation({
      container: divRef.current,
      path: '/lotties/loader.json',
      loop: true,
      autoplay: true
    });
  }, []);

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-white">
      <div className="w-11/12 max-w-[200px]" ref={divRef}></div>
    </div>
  );
};

export default LottieLoader;
