import {
  BlockScreen,
  AudienceScreen,
  SubscriptionsScreen,
  AnalyticsScreen,
  ProductsScreen,
  PurchaseScreen
} from 'types/App';

export enum AppTypes {
  edit_block_screen = 'edit_block_screen',
  edit_audience_screen = 'edit_audience_screen',
  edit_subscriptions_screen = 'edit_subscriptions_screen',
  edit_analytics_screen = 'edit_analytics_screen',
  edit_products_screen = 'edit_products_screen',
  edit_purchase_screen = 'edit_purchase_screen',
  edit_profile_viewer = 'edit_profile_viewer',
  user_logout = 'user_logout'
}

interface EditBlockScreen {
  type: AppTypes.edit_block_screen;
  payload: BlockScreen;
}

interface EditAudienceScreen {
  type: AppTypes.edit_audience_screen;
  payload: AudienceScreen;
}

interface EditSubscriptionsScreen {
  type: AppTypes.edit_subscriptions_screen;
  payload: SubscriptionsScreen;
}

interface EditAnalyticsScreen {
  type: AppTypes.edit_analytics_screen;
  payload: AnalyticsScreen;
}

interface EditProductsScreen {
  type: AppTypes.edit_products_screen;
  payload: ProductsScreen;
}

interface EditPurchaseScreen {
  type: AppTypes.edit_purchase_screen;
  payload: PurchaseScreen;
}

interface EditProfileViewer {
  type: AppTypes.edit_profile_viewer;
  payload: boolean;
}

interface UserLogout {
  type: AppTypes.user_logout;
  payload: null;
}

export type AppActions =
  | EditBlockScreen
  | EditAudienceScreen
  | EditSubscriptionsScreen
  | EditAnalyticsScreen
  | EditProductsScreen
  | EditPurchaseScreen
  | EditProfileViewer
  | UserLogout;
