import { ILangContext } from "./LangContext";

const langReducer = (state: ILangContext, action: any) => {
    const { type, payload } = action;

    const langsAvailable = ["english_us", "spanish_es"];

    if (langsAvailable.includes(type)) {
        return payload
    }

    return state
}

export default langReducer;