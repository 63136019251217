export const getURL = () => {
  const url =
    process?.env?.URL && process.env.URL !== ''
      ? process.env.URL
      : process?.env?.VERCEL_URL && process.env.VERCEL_URL !== ''
      ? process.env.VERCEL_URL
      : 'http://localhost:3000';
  return url.includes('http') ? url : `https://${url}`;
};

export const getData = async ({ url }) => {
  try {
    const res: any = await fetch(url, {
      method: 'GET',
      headers: new Headers({ 'Content-Type': 'application/json' }),
      credentials: 'same-origin'
    });

    const data = await res.json();

    if (data.message !== 'OK') {
      return { error: data.message };
    }

    return { data };
  } catch (error) {
    console.log('error', error);
    return { error: 'Error al conectarse al servidor' };
  }
};

export const postDataV2 = async ({ url, data = {} }) => {
  const res: any = await fetch(url, {
    method: 'POST',
    headers: new Headers({ 'Content-Type': 'application/json' }),
    credentials: 'same-origin',
    body: JSON.stringify(data)
  });

  if (res.error) {
    throw res.error;
  }

  return res.json();
};

export const postData = async ({ url, token, data = {} }) => {
  const res: any = await fetch(url, {
    method: 'POST',
    headers: new Headers({ 'Content-Type': 'application/json', token }),
    credentials: 'same-origin',
    body: JSON.stringify(data)
  });

  if (res.error) {
    throw res.error;
  }

  return res.json();
};

export const toDateTime = (secs) => {
  var t = new Date('1970-01-01T00:30:00Z'); // Unix epoch start.
  t.setSeconds(secs);
  return t;
};
