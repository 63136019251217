import Axios from 'axios';

// Config
import plausibleCfg from 'config/plausibleCfg';

const apiBaseURL: string = process.env.NEXT_PUBLIC_API_PRODUCTION_URL
  ? process.env.NEXT_PUBLIC_API_PRODUCTION_URL
  : process.env.NEXT_PUBLIC_API_DEVELOPMENT_URL
  ? process.env.NEXT_PUBLIC_API_DEVELOPMENT_URL
  : 'http://localhost:4000';

export const AxiosApi = Axios.create({
  baseURL: `${apiBaseURL}/api`
});

export const AxiosPlausible = Axios.create({
  baseURL: `https://plausible.io/api`,
  headers: {
    Authorization: `Bearer ${plausibleCfg.TOKEN}`
  }
});

export const AxiosPaypal = Axios.create({
  baseURL: `https://api-m.${process.env.NEXT_PUBLIC_PRODUCTION_MODE === "true" ? "" : "sandbox."}paypal.com`
});
