// Redux
import { useSelector, useDispatch } from 'react-redux';
import {
  userAuthenticating,
  editUser,
  userLogout,
  userEditFields
} from 'reduxState/user/userState';

// Types
import { IUserRedux, IUser, IUserEdit, IUserEditSettings } from 'types/User';
import { getData } from 'utils/helpers';

const useUser = () => {
  const dispatch = useDispatch();
  const userData: IUserRedux = useSelector((state: any) => state.user);

  const getIsConnectAccountEnabled = async () => {
    const { error } = await getData({
      url: `/api/verify-connect-account?connect_id=${userData.data.connect_id}`
    });

    if (error) {
      return false;
    }

    return true;
  };

  const loggin = (token: string, payload: IUser) => {
    document.cookie = `token=${token}; path=/;`;

    return dispatch(userAuthenticating(payload));
  };

  const updateUserRedux = (payload: IUserEdit) => {
    if (Object.entries(payload).length === 0) return;

    return dispatch(editUser(payload));
  };

  const userEditSettingsFields = (payload: IUserEditSettings) => {
    const theDispatch = userEditFields(payload);

    dispatch(theDispatch);
  };

  const logout = () => dispatch(userLogout());

  return {
    user: userData.data,
    status: userData.status,
    userEdit: userData.editData,
    loggin,
    updateUserRedux,
    userEditSettingsFields,
    logout,
    getIsConnectAccountEnabled
  };
};

export { useUser };
