import { IUserRedux, IUser, IUserEditSettings } from 'types/User';
import { userTypes } from '../types';

const initialStateUserEdit: IUserEditSettings = {
  username: '',
  name: '',
  email: '',
  biography: '',
  cover_picture: {
    url: '',
    blob: null
  },
  cover_picture_blurhash: '',
  profilePicture: {
    url: '',
    blob: null
  },
  stream_alerts_cfg: {
    small: {},
    medium: {},
    large: {}
  },
  customProfile: {}
};

const initialUserData: IUser = {
  id: '',
  username: '',
  platform: '',
  email: '',
  profilePicture: '',
  cover_picture: '',
  cover_picture_blurhash: '',
  biography: '',
  registerProcess: 0,
  subStep: 1,
  blocks: null,
  subscriptionEnabled: false,
  donationEnabled: false,
  nftsEnabled: false,
  linksAccounts: {},
  linked_accounts: {
    phantom: { addr: '' },
    paypal: { merchant_id: '', subscription: { plan_id: '', product_id: '' } }
  },
  social_medias: {
    instagram: '',
    spotify: '',
    tiktok: '',
    facebook: '',
    clubhouse: '',
    opensea: '',
    twitch: '',
    twitter: '',
    youtube: '',
    snapchat: '',
    pinterest: ''
  },
  is_verified: false,
  is_email_verified: false,
  referred: false,
  stream_alerts_cfg: {
    small: {},
    medium: {},
    large: {}
  },
  customProfile: {},
  is_admin: false,
  internal_options: {
    links_accounts: {
      paypal: { enabled: false },
      stripe: { enabled: false },
      phantom: { enabled: false },
      metamask: { enabled: false }
    },
    blocks: {
      audio: true,
      digital_product: true,
      donation: true,
      greeting: true,
      nft_carousel: true,
      nft_collection_hub: true,
      question: true,
      subscription: true
    }
  }
};

const initialState: IUserRedux = {
  data: initialUserData,
  editData: initialStateUserEdit,
  status: 0
};

const userReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case userTypes.authenticated:
      return {
        ...state,
        data: payload,
        editData: {
          ...state.editData,
          username: payload.username,
          name: payload.name,
          email: payload.email,
          biography: payload.biography,
          social_medias: payload.social_medias,
          profilePicture: {
            ...state.editData.profilePicture,
            url: payload.profilePicture
          },
          cover_picture: {
            ...state.editData.cover_picture,
            url: payload.cover_picture
          },
          cover_picture_blurhash: payload.cover_picture_blurhash,
          stream_alerts_cfg: payload.stream_alerts_cfg,
          customProfile: payload.customProfile
        },
        status: 1
      };

    case userTypes.no_authenticated:
      return {
        ...state,
        status: 2
      };

    case userTypes.authenticating:
      return {
        ...state,
        data: payload,
        editData: {
          ...state.editData,
          username: payload.username,
          name: payload.name,
          email: payload.email,
          biography: payload.biography,
          social_medias: payload.social_medias,
          profilePicture: {
            ...state.editData.profilePicture,
            url: payload.profilePicture
          },
          cover_picture: {
            ...state.editData.cover_picture,
            url: payload.cover_picture
          },
          cover_picture_blurhash: payload.cover_picture_blurhash,
          stream_alerts_cfg: payload.stream_alerts_cfg,
          customProfile: payload.customProfile
        },
        status: 1
      };

    case userTypes.edit_user:
      return {
        ...state,
        data: {
          ...state.data,
          ...payload
        }
      };

    case userTypes.edit_user_fields:
      return {
        ...state,
        editData: {
          ...state.editData,
          ...payload
        }
      };

    case userTypes.logout:
      return {
        ...state,
        data: initialUserData,
        editData: initialStateUserEdit,
        status: 2
      };
    default:
      return state;
  }
};

export default userReducer;
