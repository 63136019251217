import { createStore, combineReducers } from 'redux';

// Reducers
import userReducer from './user/userReducer';
import notificationReducer from './notification/notificationReducer';
import appReducer from './app/appReducer';

const reducers = combineReducers({
  user: userReducer,
  notification: notificationReducer,
  app: appReducer
});

export type RootState = ReturnType<typeof reducers>;

const store = createStore(reducers);

export default store;
