import { INotification, INotificationRedux } from 'types/Notification';
import { notificationTypes } from '../types';

const initialState: INotification = {
  features: [],
  pendingCount: 0
};

const initialStateRedux: INotificationRedux = {
  data: initialState,
  status: 0
};

const notificationReducer = (state = initialStateRedux, action: any) => {
  const { type, payload } = action;

  switch (type) {
    case notificationTypes.all_features:
      return {
        ...state,
        data: payload,
        status: 1
      };
    case notificationTypes.edit_feature:
      const findIndex = state.data.features.findIndex(
        (feature: any) => feature.id === payload.id
      );

      if (findIndex === -1) return state;

      const updateFeatures = {
        ...state.data.features[findIndex],
        ...payload
      };

      const copyFeatures = [...state.data.features];

      copyFeatures.splice(findIndex, 1);

      copyFeatures.push(updateFeatures);

      copyFeatures.sort(
        (a: any, b: any) =>
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      );

      const reducedResult = copyFeatures.reduce((acc: any, curr: any) => {
        if (curr.status === 'pending') {
          return [curr, ...acc];
        }
        return [...acc, curr];
      }, []);

      return {
        ...state,
        data: {
          ...state.data,
          features: reducedResult,
          pendingCount: state.data.pendingCount - 1
        },
        status: 1
      };
    case notificationTypes.push_feature:
      const copyFeatures_1 = [...state.data.features];
      const findIndexLastPending = copyFeatures_1.findIndexLast((value: any) => value.status === "pending")
     
      copyFeatures_1.splice(findIndexLastPending === -1 ? 0 : findIndexLastPending + 1, 0, payload)      
  
      return {
        ...state,
        data: {
          ...state.data,
          features: copyFeatures_1,
          pendingCount: payload.type === "donation" ? state.data.pendingCount : state.data.pendingCount + 1
        }
      }
    default:
      return state;
  }
};

export default notificationReducer;
