export const userTypes = {
  authenticated: '@user_authenticated',
  no_authenticated: '@user_no_authenticated',
  authenticating: '@user_authenticating',
  edit_user: '@user_edit_user',
  logout: '@user_logout',
  edit_user_fields: '@user_edit_user_fields'
};

export const notificationTypes = {
  all_features: '@notification_all_features',
  edit_feature: '@notification_edit_feature',
  push_feature: '@notification_push_feature'
};
